<template>
  <v-dialog
    v-model="dialog"
    :max-width="movement.invoice && !edit ? '80%' : '500'"
  >
    <template v-slot:activator="{ on }">
      <a v-on="on">{{ movement.invoice_number || "-" }}</a>
    </template>
    <v-card id="uploadFileCard">
      <form
        action
        data-vv-scope="design-form"
        @submit.prevent="validateForm('design-form')"
      >
        <v-card-title
          style="text-transform: uppercase; text-decoration: underline"
          >{{ $tc("payroll") }}
        </v-card-title>
        <div class="close">
          <v-btn icon @click="dialog = false" small>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <v-card-text class="pb-1">
          <v-row v-if="!movement.invoice || edit">
            <v-col cols="12" class="pt-4 pb-0">
              <file-upload
                v-if="dialog"
                :multiple="false"
                :drop="true"
                :drop-directory="false"
                accept="image/*, .doc,.docx,.xls,.xlsx, .pdf"
                v-model="files"
                ref="upload"
                class="pt-2 file-upload pointer"
              >
                <div
                  style="
                    border: dashed var(--v-primary-base) 3px;
                    border-radius: 10px;
                    min-height: 50px;
                    text-align: center;
                    color: var(--v-primary-base);
                    cursor: pointer;
                  "
                >
                  <div
                    v-if="files.length"
                    style="height: 100%; cursor: pointer"
                  >
                    <template v-for="(file, i) in files">
                      <v-chip
                        v-if="i < 2"
                        :key="file.id"
                        outlined
                        color="primary"
                        style="
                          max-width: 30%;
                          vertical-align: middle;
                          margin-top: 6px;
                        "
                        class="no-wrap mx-1"
                        >{{ file.name }}
                      </v-chip>
                      <v-chip
                        v-if="i == 2"
                        :key="file.id"
                        outlined
                        color="primary"
                        class="mx-1"
                        style="margin-top: 6px"
                      >
                        + {{ files.length - 2 }}
                        {{ $tc("file", files.length - 2) }}
                      </v-chip>
                    </template>
                  </div>
                  <!--Estilo movil-->
                  <div style="cursor: pointer" v-if="files.length === 0">
                    <h4 class="message pt-3" style="font-weight: normal">
                      <v-icon size="12" color="primary">$clip</v-icon>
                      {{ $t("designs.drop") }}
                    </h4>
                  </div>

                  <div class="example-btn"></div>
                </div>
              </file-upload>
            </v-col>
            <v-col cols="12" v-if="$store.state.auth.user.id == 9">
              <v-btn @click="$emit('generate')" elevation="0">
                Generar nueva factura
              </v-btn>
            </v-col>
          </v-row>
          <v-row v-else justify="center">
            <v-col v-if="fileType(movement.url) === 'pdf'" cols="9">
              <pdf
                :src="movement.url"
                @num-pages="pageCount = $event"
                :page="currentPage"
                class="scrollable"
              >
                <template slot="loading">loading content here...</template>
              </pdf>
              <v-pagination
                v-model="currentPage"
                :length="pageCount"
              ></v-pagination>
            </v-col>
            <v-col cols="9" v-else-if="fileType(movement.url) === 'img'">
              <v-img
                :src="movement.url"
                max-width="100%"
                style="margin: auto"
              />
            </v-col>
            <v-col cols="12" v-else-if="fileType(movement.url) === 'office'">
              <VueDocPreview
                :value="movement.url"
                :type="fileType(movement.url)"
                :height="100"
                >other previer</VueDocPreview
              >
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="pr-4">
          <div class="flex-grow-1"></div>

          <template v-if="!movement.invoice || edit">
            <v-btn
              outlined
              @click="
                dialog = false;
                files = [];
              "
              style="height: 25px; width: 100px"
              elevation="0"
              >{{ $t("cancel") }}</v-btn
            >
            <v-btn
              type="submit"
              style="
                width: 100px !important;
                color: #363533;
                height: 25px;
                padding-top: 10px !important;
              "
              elevation="0"
            >
              <v-icon style="margin-right: 5px" size="14px">$save</v-icon>
              {{ $t("save", { name: $t("") }) }}
            </v-btn>
          </template>
          <v-btn
            v-else
            type="submit"
            style="
              width: 100px !important;
              color: #363533;
              height: 25px;
              padding-top: 10px !important;
            "
            elevation="0"
            @click="edit = true"
          >
            <v-icon style="margin-right: 5px" size="14px">$edit</v-icon>
            {{ $t("edit", { name: $t("") }) }}
          </v-btn>
        </v-card-actions>
      </form>
    </v-card>
  </v-dialog>
</template>

<script>
import FileUpload from "vue-upload-component";
import { mapActions } from "vuex";
export default {
  data() {
    return {
      dialog: false,
      files: [],
      edit: false,
      currentPage: 1,
      pageCount: null,
    };
  },
  props: ["movement"],
  components: {
    FileUpload,
    VueDocPreview: () => import("vue-doc-preview"),
    pdf: () => import("vue-pdf"),
  },

  methods: {
    ...mapActions("inventorymovements", ["inventorymovementsAddInvoce"]),
    fileType(filename) {
      // //console.log("type", filename);
      //console.log("type");
      filename = filename.split(".");
      let type = filename[filename.length - 1];
      switch (type.toLowerCase()) {
        case "jpg":
        case "jpeg":
        case "png":
          return "img";
        case "pdf":
          return "pdf";
        case "docx":
        case "pptx":
        case "xlsx":
          return "office";

        case "txt":
          return "text";

        default:
          return type;
      }
    },
    validateForm(scope) {
      this.errors.clear();
      this.userProcessed = false;
      this.$validator.validateAll(scope).then((result) => {
        if (result & (this.files.length > 0)) {
          let formData = new FormData();
          formData.append("invoice", this.movement.id);
          this.files.forEach((file) => {
            formData.append("files[]", file.file);
          });
          this.inventorymovementsAddInvoce(formData).then((data) => {
            //console.log(data);
            this.movement.invoice = data.invoice;
            this.movement.url = data.url;
            this.edit = false;
          });
        }
      });
    },
  },
};
</script>
<style lang="sass">


.v-sheet button.v-btn.v-size--default:not(.v-btn--icon):not(.v-btn--fab)
  padding: 10px 15px !important
.file-upload
  width: 100%
  min-height: 50px
  cursor: !important

.upload
  min-height: 100px
  cursor: !important
  border-radius: 10px
  border: dashed grey 2px
  text-align: center
  .message
    padding-top: 15px
#uploadFileCard

@media (max-width: 400px)
  .upload
    height: 150px
  .file-upload
    min-height: 100px
</style>
